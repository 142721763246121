
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "report-building-house-insurance-claim",
  components: { Form, Field, ErrorMessage, ImagesUploader },
  // prop: ["publicId"],
  data() {
    return {
      icClass: "col-xl-12",
      hprClass: "col-xl-6",
      fbClass: "col-xl-6",
      dtClass: "col-xl-6",
      afaClass: "col-xl-6",
      natureClass: "col-xl-12",
      hasFireBrigadeReport: false,
      hasPoliceReport: false,
      claimNature: "",
      itemPublicId: "",
      lossDate: "",
      minimumDate: "",
      todaysDate: "",
      policyStartDate: "",
      policeReport: "",
      amount: 0.0 as number,
      policy: "",
      affectedAreaPicture: "",
      fireBrigadeReport: "",
      affectedAreaImages: "",
      descriptionOfIncident: "",
      acceptTerms: false,
      affectedItems: [
        {
          itemName: "",
          amount: "",
          receipt: "",
        },
      ],
      policies: [],
      buildings: [],
      // publicId: this.$route.params.publicId,
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const claimsValidator = Yup.object().shape({
      policy: Yup.mixed()
        .required()
        .label("Policy reference"),
      itemPublicId: Yup.mixed()
        .required()
        .label("Building"),
      claimNature: Yup.mixed()
        .required()
        .label("Claim nature"),
      lossDate: Yup.date()
        .required()
        .label("Date and time of incident"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Full description of incident"),
      acceptTerms: Yup.boolean()
        .oneOf([true])
        .required()
        .label("Terms & conditions"),
    });

    const submitForm = (values, { resetForm }) => {
      // console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const form = document.forms[0];
            const formData = new FormData(form);

            ApiService.setHeader();
            ApiService.post(variables.CLAIMS_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "cooperative-admin-members-claims-listing",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Building", [
        "Claims",
        "Report Claims",
        "House Insurance Claim",
      ]);
    });

    return { submitButton1, claimsValidator, submitForm };
  },
  created() {
    // Get the current date
    this.getCurrentDate();

    // Get the list of policies associated with this claim type
    this.getPolicies();

    //Set page title
    document.title =
      "Cooperatve: Building House Insurance Claim | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.COOPERATIVE_UMRELLA_POLCIES_ROUTE}/HOM`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(event) {
      this.policies.forEach((item) => {
        if (item["publicId"] === event.target.value) {
          if (item["policies"] == "") {
            return variables.toastAlert(
              "No policies found for this umbrella policy.",
              "error"
            );
          }

          this.buildings = item["policies"];
          this.minimumDate = item["policies"][0]["startDate"];
        }
      });
    },
    setPolicyId(event) {
      this.policy =
        event.target.options[event.target.selectedIndex].dataset.policy;
    },
    getclaimNature(event) {
      this.defaultValues();

      const value = event.target.options[event.target.selectedIndex].value;

      // If the claim nature is accident
      if (value == "Fire") {
        this.hasFireBrigadeReport = true;
        this.dtClass = "col-xl-4";
        this.afaClass = "col-xl-4";
        this.natureClass = "col-xl-6";
      } else if (value == "Theft") {
        this.hasPoliceReport = true;
        this.dtClass = "col-xl-4";
        this.afaClass = "col-xl-4";
        this.natureClass = "col-xl-6";
      } else {
        this.defaultValues();
      }
    },
    estimatedRepairsCalculation(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);

      // Check if value is a number
      if (isNaN(value) == false) {
        event.target.textContent = value;
        // Append total amount of affected areas amount to the amount field
        this.amount = variables.totalEstimateOfRepairs();
      } else {
        return;
      }
    },
    getCurrentDate() {
      const currentYear = new Date().getFullYear();
      this.minimumDate = currentYear + "-01-01";
      this.todaysDate = variables.currentDate();
    },
    defaultValues() {
      this.icClass = "col-xl-12";
      this.hprClass = "col-xl-6";
      this.fbClass = "col-xl-6";
      this.dtClass = "col-xl-6";
      this.afaClass = "col-xl-6";
      this.natureClass = "col-xl-12";
      this.hasFireBrigadeReport = false;
      this.hasPoliceReport = false;
    },
    addDynamicField() {
      this.affectedItems.push({
        itemName: "",
        amount: "",
        receipt: "",
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      this.affectedItems.splice(counter, 1);
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(amount) {
      return variables.addCommaToNumber(amount);
    },
  },
});
